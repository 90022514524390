.App {
  text-align: center;
}

.upper-bun{
  margin-bottom: 8px;
}

.tomato div{
  background-color: red;
    border-radius: 12px;
    height: 18px;
    width: 136px;
    margin-bottom: 10px;
}

.lettuse{
  background-color: rgb(89, 223, 89);
  height: 36px;
  width: 460px;
  margin-inline: auto;
  border-radius: 12px;
  margin-bottom: 10px;
}

.cheese{
  background-color: rgb(226, 209, 111);
  height: 12px;
  width: 460px;
  margin-inline: auto;
  border-radius: 12px;
  margin-bottom: 10px;
}

.meat{
  background-color: rgb(139, 8, 8);
  height: 48px;
  width: 460px;
  margin-inline: auto;
  border-radius: 24px;
  margin-bottom: 10px;
}
/* Bottom part */

.recepie{
  background-color: #b6b6b6;
  display: flex;
  justify-content: center;
  padding: 24px;
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.recepie_table{
  padding: 10px;
  background-color: rgb(232, 240, 160);
  max-width: fit-content;
}

.d-flex{
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
}
